<template>
    <template v-if="lastNews">
        <div class="io-container tw-mt-28 tw-flex tw-flex-col tw-gap-28">
            <section class="tw-grid tw-grid-cols-1 tw-gap-16 lg:tw-grid-cols-2">
                <img
                    alt="asd"
                    class="tw-aspect-video tw-w-full tw-rounded-3xl tw-object-cover tw-object-center"
                    :src="lastNews.image ? useMedia(lastNews.image).medium : lastNews.imgUrl" />
                <div class="tw-flex tw-flex-col tw-gap-4">
                    <h3>{{ parseDate(lastNews.publishDate) }}</h3>
                    <h2 class="tw-mb-4" v-html="lastNews.title"></h2>
                    <div class="h3" v-html="lastNews.description"></div>
                    <p class="h3 tw-mt-auto tw-font-medium tw-text-blue">{{ lastNewsTags }}</p>
                    <v-btn
                        class="tw-h-10 tw-px-4 max-md:tw-ms-auto md:tw-me-auto"
                        color="blue"
                        rounded="pill">
                        <a
                            :href="lastNews.url"
                            target="_blank"
                            >Ler mais</a
                        >
                    </v-btn>
                </div>
            </section>
            <section>
                <div class="tw-flex tw-items-center tw-justify-between">
                    <h2 class="tw-m-0">Últimas <span class="tw-text-blue">notícias</span></h2>
                    <v-btn
                        class="tw-text-base"
                        color="blue"
                        elevation="0"
                        :to="{ name: 'AllNews' }"
                        variant="text">
                        <span class="tw-mr-3">Ver todas</span>
                        <v-icon>mdi-arrow-top-right</v-icon>
                    </v-btn>
                </div>
                <div class="tw-mt-10 tw-grid tw-grid-cols-1 tw-gap-x-8 tw-gap-y-16 sm:tw-grid-cols-2 lg:tw-grid-cols-3 3xl:tw-grid-cols-4">
                    <article
                        v-for="i in news"
                        :key="i.id"
                        class="tw-flex tw-flex-col">
                        <img
                            alt=""
                            class="tw-aspect-video tw-rounded-3xl tw-object-cover tw-object-center"
                            :src="i.image ? useMedia(i.image).small : i.imgUrl" />
                        <p class="tw-mt-6 tw-font-normal tw-text-dark">{{ parseDate(i.publishDate) }}</p>
                        <h4 class="tw-mt-3 tw-text-lg tw-font-semibold lg:tw-text-lg xl:tw-text-xl" v-html="i.title"></h4>
                        <div class="xl:tw-mt-3 xl:tw-text-lg" v-html="i.description"></div>
                        <v-btn
                            class="tw-mt-3 tw-h-10 tw-px-4 max-md:tw-ms-auto md:tw-me-auto xl:tw-mt-6"
                            color="blue"
                            rounded="pill">
                            <a
                                :href="i.url"
                                target="_blank"
                                >Ler mais</a
                            >
                        </v-btn>
                    </article>
                </div>
            </section>
        </div>
    </template>
    <template v-else>
        <!-- loader -->
        <Loader />
    </template>
</template>

<script setup lang="ts">
    import { useMedia } from '../../composables/useMedia';
    import moment from 'moment';
    import Loader from '@/components/Loader.vue';
    import { useAppStore } from '@/store/app';

    const appStore = useAppStore();

    const lastNews = computed(() => appStore.getNews[0]);
    const news = computed(() => appStore.getNews.slice(1));

    const lastNewsTags = computed(() => {
        return lastNews.value?.tags.map((tag: any) => tag.name).join(', ');
    });

    function parseDate(date: string) {
        return moment(date).format('DD MMMM, YYYY');
    }
</script>

<style scoped></style>
