<template>
    <div class="tw-mt-8 tw-grid tw-grid-cols-1 tw-gap-6 md:tw-grid-cols-2 3xl:tw-gap-x-12 3xl:tw-gap-y-8">
        <Product
            v-for="(product, i) in props.products"
            :key="i"
            :product="product" />
    </div>
</template>

<script setup lang="ts">
    import Product from './Product.vue';

    const props = defineProps<{
        products: {
            color: string;
            name: string;
            description: string;
        }[];
    }>();
</script>

<style scoped></style>
