<template>
    <svg
        height="16.077"
        viewBox="0 0 16.077 16.077"
        width="16.077"
        xmlns="http://www.w3.org/2000/svg">
        <path
            id="Caminho_988"
            d="M1229.921,39.9V30.1a.93.93,0,0,0-.9-.951H1219.8a.957.957,0,0,0,0,1.91h8.325v8.828a.906.906,0,1,0,1.809,0Z"
            data-name="Caminho 988"
            fill="currentColor"
            transform="translate(-833.002 -882.498) rotate(45)" />
    </svg>
</template>

<script setup lang="ts"></script>

<style scoped></style>
