<template>
    <svg
        height="22"
        viewBox="0 0 31.289 22"
        width="31.289"
        xmlns="http://www.w3.org/2000/svg">
        <g
            id="email_3_"
            data-name="email (3)"
            transform="translate(0 -76)">
            <g
                id="Grupo_47"
                data-name="Grupo 47"
                transform="translate(1.603 88.299)">
                <g
                    id="Grupo_46"
                    data-name="Grupo 46">
                    <path
                        id="Caminho_968"
                        d="M44.9,277.251l-2.621,2.629a2.9,2.9,0,0,1-4.018,0l-2.621-2.629-9.412,9.443a2.723,2.723,0,0,0,1.147.259H53.162a2.721,2.721,0,0,0,1.147-.259Z"
                        data-name="Caminho 968"
                        fill="currentColor"
                        transform="translate(-26.226 -277.251)" />
                </g>
            </g>
            <g
                id="Grupo_49"
                data-name="Grupo 49"
                transform="translate(1.603 76)">
                <g
                    id="Grupo_48"
                    data-name="Grupo 48">
                    <path
                        id="Caminho_969"
                        d="M53.164,76H27.375a2.722,2.722,0,0,0-1.147.259L36.285,86.35h0v0l3.27,3.281a1.1,1.1,0,0,0,1.422,0l3.27-3.281v0h0L54.311,76.259A2.721,2.721,0,0,0,53.164,76Z"
                        data-name="Caminho 969"
                        fill="currentColor"
                        transform="translate(-26.228 -76)" />
                </g>
            </g>
            <g
                id="Grupo_51"
                data-name="Grupo 51"
                transform="translate(0 77.541)">
                <g
                    id="Grupo_50"
                    data-name="Grupo 50">
                    <path
                        id="Caminho_970"
                        d="M.292,101.212A2.716,2.716,0,0,0,0,102.421v16.5a2.715,2.715,0,0,0,.292,1.209l9.428-9.459Z"
                        data-name="Caminho 970"
                        fill="currentColor"
                        transform="translate(0 -101.212)" />
                </g>
            </g>
            <g
                id="Grupo_53"
                data-name="Grupo 53"
                transform="translate(21.568 77.541)">
                <g
                    id="Grupo_52"
                    data-name="Grupo 52">
                    <path
                        id="Caminho_971"
                        d="M362.361,101.21l-9.428,9.46,9.428,9.459a2.717,2.717,0,0,0,.292-1.209v-16.5A2.717,2.717,0,0,0,362.361,101.21Z"
                        data-name="Caminho 971"
                        fill="currentColor"
                        transform="translate(-352.933 -101.21)" />
                </g>
            </g>
        </g>
    </svg>
</template>

<script setup lang="ts"></script>

<style scoped></style>
