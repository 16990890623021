<template>
    <v-app-bar
        class="app-bar"
        :class="{ 'is-transparent': shouldBeTransparent }"
        :color="shouldBeTransparent ? 'transparent' : 'blue'"
        :elevation="shouldBeTransparent ? 0 : 2"
        :height="$display.mdAndUp.value ? 100 : 70">
        <template #append>
            <v-app-bar-nav-icon
                class="lg:tw-hidden"
                elevation="0"
                @click="openSidebar"></v-app-bar-nav-icon>
        </template>
        <div class="tw-mx-auto tw-flex tw-w-full tw-grid-cols-12 tw-items-center tw-px-8 xl:tw-container lg:tw-grid">
            <router-link
                class="tw-col-span-6 tw-flex tw-items-start tw-justify-start max-lg:tw-flex-1 md:tw-col-span-2"
                :to="{ name: 'Home' }">
                <v-img
                    :height="$display.xlAndUp.value ? 40 : 35"
                    position="left"
                    src="@/assets/logo.svg"></v-img>
            </router-link>

            <div class="navbar-links tw-col-span-8 tw-hidden tw-gap-8 lg:tw-flex xl:tw-col-span-7 xl:tw-gap-10">
                <template
                    v-for="(item, key) in navigation"
                    :key="key">
                    <v-menu
                        v-if="item.children && item.children.length > 0"
                        class="sub-menu"
                        eager
                        location="bottom"
                        open-delay="100"
                        open-on-click
                        open-on-hover
                        transition="slide-y-transition">
                        <template #activator="{ props }">
                            <a
                                class="tw-cursor-pointer tw-text-white"
                                v-bind="props">
                                {{ item.title }}
                            </a>
                        </template>
                        <v-list
                            bg-color="blue"
                            elevation="0">
                            <v-list-item
                                v-for="(child, key) in item.children"
                                :key="key"
                                :title="child.title"
                                :to="child.route">
                            </v-list-item>
                        </v-list>
                    </v-menu>
                    <router-link
                        v-else
                        class="tw-text-white"
                        :to="item.route">
                        {{ item.title }}
                    </router-link>
                </template>
            </div>
            <div
                v-if="contactsPage"
                class="tw-col-span-6 tw-hidden tw-items-end tw-justify-end tw-gap-3 md:tw-col-span-2 lg:tw-flex xl:tw-col-span-3">
                <v-btn
                    v-if="contactsPage.email"
                    class="tw-text-white"
                    elevation="0"
                    height="45"
                    :href="'mailto:' + contactsPage.email"
                    :icon="$display.xlAndUp.value ? false : true"
                    rounded="pill"
                    variant="outlined">
                    <emailIcon class="tw-h-3"></emailIcon>
                    <span class="tw-ml-1 tw-hidden tw-text-base tw-font-light tw-capitalize xl:tw-block">Email</span>
                </v-btn>
                <v-btn
                    v-if="contactsPage.phoneNumber"
                    color="blue-70"
                    elevation="0"
                    height="45"
                    :href="'tel:' + contactsPage.phoneNumber"
                    :icon="$display.xlAndUp.value ? false : true"
                    rounded="pill"
                    variant="flat">
                    <phoneIcon class="tw-h-4"></phoneIcon>
                    <span class="tw-ml-1 tw-hidden tw-text-base tw-font-light tw-capitalize xl:tw-block">Ligar</span>
                </v-btn>
            </div>
        </div>
    </v-app-bar>
    <v-navigation-drawer
        class="lg:tw-hidden"
        color="blue"
        image="@/assets/gradients/gradient-background.png"
        location="top"
        :model-value="shouldBeOpen"
        @update:model-value="isSidebarOpen = $event">
        <v-list
            density="compact"
            nav>
            <template
                v-for="(item, key) in navigation"
                :key="key">
                <v-list-item
                    v-if="!item.children || item.children.length === 0"
                    :title="item.title"
                    :to="item.route">
                </v-list-item>
                <v-list-group
                    v-else
                    :value="item.title">
                    <template #activator="{ props }">
                        <v-list-item
                            v-bind="props"
                            :title="item.title"></v-list-item>
                    </template>
                    <v-list-item
                        v-for="(child, key) in item.children"
                        :key="key"
                        :title="child.title"
                        :to="child.route">
                    </v-list-item>
                </v-list-group>
            </template>
        </v-list>
        <template #image>
            <img
                alt=""
                class="tw-object-left-top"
                src="@/assets/gradients/gradient-background.png" />
        </template>
    </v-navigation-drawer>
</template>

<script setup lang="ts">
    import emailIcon from '@/components/icons/email.vue';
    import phoneIcon from '@/components/icons/phone.vue';
    import { useAppStore } from '../store/app';
    import { useDisplay } from 'vuetify';
    import { useWindowScroll } from '@vueuse/core';

    const appStore = useAppStore();
    const $display = useDisplay();
    const isSidebarOpen = ref(false);
    const $router = useRouter();
    const $route = useRoute();
    const { y } = useWindowScroll();

    function openSidebar() {
        isSidebarOpen.value = !isSidebarOpen.value;
    }

    const contactsPage = computed(() => {
        return appStore.getContacts;
    });

    const shouldBeTransparent = computed(() => {
        return $route.name === 'Home' && $display.lgAndUp.value && y.value < 10;
    });

    const shouldBeOpen = computed(() => {
        if ($display.lgAndUp.value) {
            return false;
        }
        return isSidebarOpen.value;
    });

    const navigation = computed(() => {
        return appStore.getMenus.map((menu) => {
            return {
                title: menu.title,
                route: menu.page ? { name: menu.page, ...(menu.pageContentSlug && { params: { slug: menu.pageContentSlug } }) } : null,
                children: menu.children.map((child) => {
                    return {
                        title: child.title,
                        route: child.page ? { name: child.page, ...(child.pageContentSlug && { params: { slug: child.pageContentSlug } }) } : null,
                    };
                }),
            };
        });
    });
    $router.afterEach(() => {
        isSidebarOpen.value = false;
    });

    watch(
        () => $display.lgAndUp.value,
        (value) => {
            if (value) {
                isSidebarOpen.value = false;
            }
        },
    );
</script>

<style lang="scss">
    .navbar-links a,
    .sub-menu a .v-list-item-title {
        @apply tw-text-base tw-font-extralight;

        &.router-link-active {
            @apply tw-font-medium;
        }
    }

    .app-bar {
        background-position: top !important;
        background-size: cover !important;
        background-repeat: no-repeat !important;
        background-image: url('@/assets/gradients/gradient-background.png') !important;
    }

    .is-transparent {
        background: none !important;
    }

    .v-toolbar__append {
        @apply lg:tw-hidden #{!important};
    }

    .sub-menu {
        .v-list {
            background: transparent !important;
        }

        a {
            &.v-list-item--active {
                .v-list-item-title {
                    @apply tw-font-normal;
                }
            }

            .v-list-item-title {
                @apply tw-text-base tw-font-thin;
            }

            .v-list-item__overlay {
                @apply tw-hidden;
            }
        }

        .v-overlay__content {
            @apply tw-bg-blue-400;
            &::before {
                content: '';
                background-position: top !important;
                // background-size: cover !important;
                background-repeat: no-repeat !important;
                background-image: url('@/assets/gradients/gradient-background.png') !important;
                position: absolute;
                inset: 0;
            }
        }
    }
</style>
