<template>
    <svg
        height="39.087"
        viewBox="0 0 39.087 39.087"
        width="39.087"
        xmlns="http://www.w3.org/2000/svg">
        <g
            id="sort-down"
            transform="translate(-45.222 -20.206) rotate(-45)">
            <path
                id="Path_924"
                d="M34.72,64.609a2.124,2.124,0,0,0-1.555-.656H2.211a2.125,2.125,0,0,0-1.555.656,2.17,2.17,0,0,0,0,3.11L16.134,83.2a2.168,2.168,0,0,0,3.109,0L34.72,67.719a2.169,2.169,0,0,0,0-3.109Z"
                data-name="Path 924"
                fill="currentColor" />
        </g>
    </svg>
</template>

<script setup lang="ts"></script>

<style scoped></style>
