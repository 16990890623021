<template>
    <v-app>
        <Navbar />
        <v-main>
            <router-view />
        </v-main>
        <Footer />
        <PromptUpdate />
    </v-app>
</template>

<script setup lang="ts">
    import Navbar from './views/Navbar.vue';
    import Footer from './views/Footer.vue';
    import PromptUpdate from '@/components/shared/PromptUpdate.vue';
    import { useAppStore } from './store/app';

    const appStore = useAppStore();

    appStore.fillStore();

    // get browser locale
    const locale = navigator.language.split('-')[0];
    appStore.setLocale('pt');
</script>
