<template>
    <Splide :options="splideOptions">
        <SplideSlide
            v-for="i in recognitions"
            :key="i.id">
            <article class="tw-flex tw-h-full tw-flex-col tw-rounded-4xl tw-bg-gray-100 tw-px-8 tw-py-16">
                <div class="tw-relative tw-mx-auto tw-aspect-square tw-max-w-36 tw-overflow-hidden">
                    <img
                        alt=""
                        class="tw-aspect-square tw-w-full tw-object-cover tw-object-center"
                        :src="useMedia(i.image).medium" />
                </div>
                <div
                    class="desc tw-grow tw-text-center tw-font-light 3xl:tw-w-10/12 tw-mx-auto tw-mt-6"
                    v-html="i.description"></div>
                <div class="tw-mt-8 tw-text-center">
                    <v-btn
                        v-if="i.knowMoreUrl"
                        class="tw-h-12"
                        color="blue"
                        :href="i.knowMoreUrl"
                        rounded="pill"
                        target="_blank"
                        >Saber mais</v-btn
                    >
                </div>
            </article>
        </SplideSlide>
    </Splide>
</template>

<script setup lang="ts">
    import { Splide, SplideSlide } from '@splidejs/vue-splide';
    import { useMedia } from '../composables/useMedia';

    const props = defineProps<{
        recognitions: any[];
    }>();

    const splideOptions = ref({
        type: 'slide',
        perPage: 3,
        perMove: 1,
        gap: '3rem',
        pagination: true,
        arrows: false,
        autoplay: true,
        interval: 5000,
        breakpoints: {
            1260: {
                perPage: 2,
                gap: '3rem',
            },
            900: {
                gap: '1rem',
            },
            760: {
                perPage: 1,
                gap: '1rem',
            },
        },
    });
</script>

<style scoped lang="scss">
    article {
        @apply tw-relative tw-overflow-hidden;

        > * {
            position: relative;
            z-index: 2;
        }

        .desc * {
            line-height: 1.75;
        }

        &::before {
            content: '';
            @apply tw-absolute tw-inset-0 tw-z-[0];
            background: url('@/assets/gradients/gradient-recognitions.png') no-repeat center center;
            background-size: cover;
        }
    }
</style>
