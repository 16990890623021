<template>
    <v-footer
        v-if="page"
        class="app-footer tw-p-0"
        color="blue">
        <div class="io-container tw-my-0 tw-pb-16 tw-pt-20">
            <v-row>
                <v-col
                    cols="12"
                    sm="6">
                    <v-img
                        class="tw-h-12"
                        position="left"
                        src="@/assets/logo.svg"></v-img>
                </v-col>
                <v-col
                    cols="12"
                    sm="6">
                    <!-- social networks -->
                    <div class="tw-flex tw-justify-between tw-gap-4 max-sm:tw-mt-8 sm:tw-justify-end">
                        <v-btn
                            v-for="(network, key) in socialNetworks"
                            :key="key"
                            class="tw-bg-white tw-bg-opacity-40 tw-text-blue"
                            elevation="0"
                            :href="network.url"
                            icon
                            target="_blank">
                            <img
                                :alt="network.name"
                                class="tw-h-6 tw-w-6"
                                :src="useMedia(network.icon).original" />
                        </v-btn>
                    </div>
                </v-col>
            </v-row>
            <div class="tw-mt-10 tw-grid tw-grid-cols-1 tw-justify-center tw-gap-6 md:tw-grid-cols-2 xl:tw-flex">
                <div
                    v-if="page.address"
                    class="tw-relative tw-flex tw-items-center tw-gap-4 tw-px-7 tw-py-4 after:tw-absolute after:tw-inset-0 after:tw-rounded-full after:tw-bg-white after:tw-opacity-10 md:tw-col-span-2">
                    <MarkerIcon class="tw-w-7 tw-min-w-7" />
                    <a
                        class="tw-font-extralight max-md:tw-text-sm"
                        :href="page.googleMapsUrl"
                        target="_blank">
                        {{ page.address }}
                    </a>
                </div>
                <div v-if="page.phoneNumberFormatted" class="tw-relative tw-flex tw-items-center tw-gap-4 tw-px-7 tw-py-4 after:tw-absolute after:tw-inset-0 after:tw-rounded-full after:tw-bg-white after:tw-opacity-10">
                    <PhoneIcon class="tw-w-7 tw-min-w-7" />
                    <a
                        class="tw-font-extralight max-md:tw-text-sm"
                        :href="`tel:${page.phoneNumber}`">
                        {{ page.phoneNumberFormatted }}
                    </a>
                </div>
                <div v-if="page.email" class="tw-relative tw-flex tw-items-center tw-gap-4 tw-px-7 tw-py-4 after:tw-absolute after:tw-inset-0 after:tw-rounded-full after:tw-bg-white after:tw-opacity-10">
                    <EmailIcon class="tw-w-7 tw-min-w-7" />
                    <a
                        class="tw-font-extralight max-md:tw-text-sm"
                        :href="`mailto:${page.email}`">
                        {{ page.email }}</a
                    >
                </div>
            </div>
        </div>
    </v-footer>
    <v-footer
        v-if="page"
        class="tw-p-0"
        color="blue">
        <div class="io-container tw-my-0 tw-grid tw-grid-cols-2 tw-gap-4 tw-py-4 tw-font-thin tw-opacity-60 max-md:tw-text-center max-md:tw-text-xs md:tw-flex lg:tw-gap-12">
            <p class="tw-col-span-2 ">@ {{ currentYear }} Copyrights by IOTech. All Rights Reserved.</p>
            <a class="md:tw-ml-auto md:tw-text-center" :href="page.termsUrl" target="_blank">Termos e condições</a>
            <a class="md:tw-text-center" :href="page.politicsUrl" target="_blank">Política de privacidade</a>
        </div>
    </v-footer>
</template>

<script setup lang="ts">
    import MarkerIcon from '@/components/icons/marker.vue';
    import PhoneIcon from '@/components/icons/phone.vue';
    import EmailIcon from '@/components/icons/email.vue';
    import { useAppStore } from '@/store/app';
    import { useMedia } from '@/composables/useMedia';

    const currentYear = new Date().getFullYear();

    const appStore = useAppStore();

    const page = computed(() => {
        return appStore.getContacts;
    });

    const socialNetworks = computed(() => {
        return page.value.socialNetworks.filter((network) => network.url && network.icon);
    });
</script>

<style lang="scss">
    .app-footer {
        position: relative;
        & * {
            z-index: 1;
        }
        &::after {
            @apply tw-absolute tw-inset-0;
            content: '';
            background-position: 0% 0% !important;
            background-size: cover !important;
            background-repeat: no-repeat !important;
            background-image: url('@/assets/gradients/gradient-background.png') !important;
            filter: opacity(0.5);
        }
    }
</style>
