<template>
    <Splide :options="splideOptions">
        <SplideSlide
            v-for="i in contentFiltered"
            :key="i.id">
            <article class="tw-relative tw-mx-auto tw-flex tw-h-full tw-flex-col tw-gap-6 tw-overflow-hidden tw-rounded-3xl tw-bg-white tw-px-8 tw-py-10 3xl:tw-px-14 3xl:tw-py-10">
                <div
                    v-if="i.text"
                    class="lg:tw-text-base tw-text-sm"
                    v-html="i.text"></div>
                <div class="tw-flex tw-flex-row tw-gap-4 tw-items-center">
                    <img
                        alt=""
                        class="tw-aspect-square tw-h-16 tw-w-16 tw-rounded-full tw-object-contain tw-object-center"
                        :class="{ 'tw-opacity-0': !i.partner?.image }"
                        :src="useMedia(i.partner?.image).small" />
                    <div>
                        <p class="tw-font-semibold">{{ i.partner.name }}</p>
                        <p class="tw-font-light tw-text-sm">{{ formatDate(i.date) }}</p>
                    </div>
                </div>
            </article>
        </SplideSlide>
    </Splide>
</template>

<script setup lang="ts">
    import { Splide, SplideSlide } from '@splidejs/vue-splide';
    import { useMedia } from '../composables/useMedia';
    import moment from 'moment'

    const props = defineProps<{
        content: any[];
    }>();

    const contentFiltered = computed(() => {
        return props.content.filter((item) => item.text && item.partner);
    });

    function formatDate(date: string) {
        return moment(date).format('DD/MM/YYYY');
    }

    const splideOptions = ref({
        type: 'slide',
        perPage: 3,
        perMove: 1,
        gap: '2rem',
        pagination: true,
        arrows: false,
        autoplay: true,
        interval: 5000,
        breakpoints: {
            1536: {
                perPage: 2,
            },
            1279: {
                perPage: 2,
                gap: '1rem',
            },
            640: {
                perPage: 1,
                gap: '1rem',
            },
        },
    });
</script>

<style scoped></style>
