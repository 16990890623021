<template>
    <template v-if="page">
        <div class="min-h-dvh tw-relative tw-flex tw-flex-col tw-overflow-hidden">
            <div class="io-container tw-relative tw-z-20 tw-my-0 tw-flex tw-flex-grow tw-flex-col tw-items-center tw-justify-start tw-py-8 tw-pb-0 tw-text-center 3xl:tw-pt-16">
                <Tag
                    :color="page.color"
                    :text="page.name" />
                <h1
                    class="tw-mt-10"
                    v-html="page.bannerTitle"></h1>
                <div
                    class="cards-grid tw-my-12 tw-grid tw-w-full tw-grow tw-gap-x-4 tw-gap-y-4 md:tw-grid-cols-2 md:tw-grid-rows-4 lg:tw-grid-cols-6 lg:tw-grid-rows-10 xl:tw-max-h-[500px] xl:tw-grid-cols-5 xl:tw-grid-rows-12 2xl:tw-gap-x-12 2xl:tw-gap-y-5">
                    <!-- col - 1 -->
                    <RecognitionCard
                        :card="page.cards[0]"
                        class="lg:tw-col-start-1 lg:tw-col-end-3 lg:tw-row-start-2 lg:tw-row-end-5 xl:tw-col-start-1 xl:tw-col-end-1 xl:tw-row-start-4 xl:tw-row-end-10" />
                    <!-- col - 2 -->
                    <RecognitionCard
                        :card="page.cards[1]"
                        class="lg:tw-col-start-3 lg:tw-col-end-5 lg:tw-row-start-1 lg:tw-row-end-3 xl:tw-col-start-2 xl:tw-col-end-2 xl:tw-row-start-2 xl:tw-row-end-6" />
                    <RecognitionCard
                        :card="page.cards[2]"
                        class="lg:tw-col-start-5 lg:tw-col-end-7 lg:tw-row-start-2 lg:tw-row-end-5 xl:tw-col-start-2 xl:tw-col-end-2 xl:tw-row-start-6 xl:tw-row-end-12" />
                    <!-- col - 3 -->
                    <RecognitionCard
                        :card="page.cards[3]"
                        class="lg:tw-col-start-3 lg:tw-col-end-5 lg:tw-row-start-3 lg:tw-row-end-9 xl:tw-col-start-3 xl:tw-col-end-3 xl:tw-row-start-1 xl:tw-row-end-13" />
                    <!-- col - 4 -->
                    <RecognitionCard
                        :card="page.cards[4]"
                        class="lg:tw-col-start-1 lg:tw-col-end-3 lg:tw-row-start-7 lg:tw-row-end-10 xl:tw-col-start-4 xl:tw-col-end-4 xl:tw-row-start-2 xl:tw-row-end-8" />
                    <RecognitionCard
                        :card="page.cards[5]"
                        class="lg:tw-col-start-3 lg:tw-col-end-5 lg:tw-row-start-9 lg:tw-row-end-11 xl:tw-col-start-4 xl:tw-col-end-4 xl:tw-row-start-8 xl:tw-row-end-12" />
                    <!-- col - 5 -->
                    <RecognitionCard
                        :card="page.cards[6]"
                        class="lg:tw-col-start-5 lg:tw-col-end-7 lg:tw-row-start-7 lg:tw-row-end-10 xl:tw-col-start-5 xl:tw-col-end-5 xl:tw-row-start-4 xl:tw-row-end-10" />
                </div>
            </div>
        </div>

        <PartnersBanner
            v-if="page.partners?.length"
            :partners="page.partners"
            sideways
            :title="page.partnersTitle" />

        <div class="io-container">
            <h2
                    class=""
                    v-html="page.recognitionTitle"></h2>
            <RecognitionsSlider
                :recognitions="page.recognition" />
        </div>

        <PartnersBanner
            v-if="page.clients?.length"
            :partners="page.clients"
            :title="page.clientsTitle" />
    </template>
    <template v-else>
        <!-- loader -->
        <Loader />
    </template>
</template>

<script setup lang="ts">
    import Loader from '@/components/Loader.vue';
    import { useMedia } from '@/composables/useMedia';
    import { useAppStore } from '@/store/app';
    import RecognitionCard from '@/components/RecognitionCard.vue';

    const appStore = useAppStore();

    const page = computed(() => {
        return appStore.getRecognition;
    });
</script>

<style scoped lang="scss">
    .min-h-dvh {
        background: transparent linear-gradient(180deg, rgba(var(--v-theme-primary), 0.25) 0%, #80808000 100%) 0% 0% no-repeat padding-box;
    }

    .cards-grid > * {
        @apply max-lg:tw-min-h-52;
    }
</style>
