import axios from '@axios';
import { AxiosPromise } from 'axios';

const API_URL = '/contact-attempts';

/**
 * Creates a new contact attempt.
 *
 * @param {string} email - The email of the contact attempt.
 * @param {string} message - The message of the contact attempt.
 * @returns {Promise<any>} A Promise that resolves to the response from the server.
 *
 * @example
 * createContactAttempt({
 *   email: 'test@example.com',
 *  message: 'Hello, World!',
 *  name: 'John Doe'
 * });
 */
export async function createContactAttempt({ email, message, name }: { email: string; message: string; name: string }): AxiosPromise {
    return axios.post(API_URL, {
        data: { email, message, name },
    });
}
