<template>
    <svg
        height="27.691"
        viewBox="0 0 27.633 27.691"
        width="27.633"
        xmlns="http://www.w3.org/2000/svg">
        <g
            id="phone-call_2_"
            data-name="phone-call (2)"
            transform="translate(-0.539 0)">
            <g
                id="Grupo_45"
                data-name="Grupo 45"
                transform="translate(0.539 0)">
                <path
                    id="Caminho_967"
                    d="M27.448,20.323l-3.864-3.864a2.57,2.57,0,0,0-4.278.966,2.63,2.63,0,0,1-3.036,1.656c-2.76-.69-6.487-4.278-7.177-7.177a2.5,2.5,0,0,1,1.656-3.036,2.57,2.57,0,0,0,.966-4.278L7.85.725a2.756,2.756,0,0,0-3.726,0L1.5,3.347c-2.622,2.76.276,10.075,6.763,16.562s13.8,9.523,16.562,6.763l2.622-2.622A2.756,2.756,0,0,0,27.448,20.323Z"
                    data-name="Caminho 967"
                    fill="currentColor"
                    transform="translate(-0.539 0)" />
            </g>
        </g>
    </svg>
</template>

<script setup lang="ts"></script>

<style scoped></style>
