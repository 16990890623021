<template>
    <div class="bottom-banner bottom-banner-blue-110 tw-py-20">
        <div class="io-container tw-relative" :class="props.sideways ? 'lg:tw-flex lg:tw-flex-row lg:tw-items-center' : ''">
            <h2
                v-if="props.title"
                :class="props.sideways ? 'lg:tw-mr-8 lg:tw-mb-0 max-lg:tw-text-center max-lg:tw-flex max-lg:tw-justify-center max-lg:tw-gap-[0.35em]' : 'tw-text-center'"
                v-html="props.title"></h2>
            <h2
                v-else
                class="tw-text-center">
                Os nossos <span class="tw-text-blue">clientes</span>
            </h2>
            <PartnersSlider :class="props.sideways ? 'lg:tw-grow' : ''" :partners="props.partners" :sideways="props.sideways" />
        </div>
    </div>
</template>

<script setup lang="ts">
    import PartnersSlider from './PartnersSlider.vue';

    const props = defineProps<{
        partners: any[];
        title?: string;
        sideways?: boolean;
    }>();
</script>

<style scoped></style>
