<template>
    <svg
        height="27.653"
        viewBox="0 0 20.125 27.653"
        width="20.125"
        xmlns="http://www.w3.org/2000/svg">
        <path
            id="pin-de-localizacao"
            d="M10.063,0A9.952,9.952,0,0,0,0,9.776a9.451,9.451,0,0,0,1.653,5.341l8.409,12.536,8.409-12.536a9.451,9.451,0,0,0,1.653-5.341A9.952,9.952,0,0,0,10.063,0Zm0,14.636A4.954,4.954,0,0,1,5.031,9.776a5.033,5.033,0,0,1,10.063,0A4.954,4.954,0,0,1,10.063,14.636Zm0,0"
            fill="currentColor" />
    </svg>
</template>

<script setup lang="ts"></script>

<style scoped></style>
