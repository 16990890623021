<template>
    <Splide :options="splideOptions">
        <SplideSlide
            v-for="i in content"
            :key="i.id">
            <article
                class="tw-relative tw-mx-auto tw-flex tw-h-full tw-flex-col tw-gap-6 tw-overflow-hidden tw-rounded-3xl tw-border tw-border-gray tw-px-8 tw-py-10 tw-text-center 3xl:tw-px-14 3xl:tw-py-10">
                <img
                    alt=""
                    class="tw-aspect-square tw-h-20 tw-w-full tw-object-contain tw-object-center"
                    :class="{ 'tw-opacity-0': !i.logo }"
                    :src="useMedia(i.logo).small" />

                <div class="tw-flex tw-flex-col tw-gap-3">
                    <h4
                        v-if="i.name"
                        class="xl:tw-text-lg tw-font-bold"
                        v-html="i.name"></h4>
                    <div
                        v-if="i.smallDescription"
                        class="tw-text-sm tw-font-light"
                        v-html="i.smallDescription"></div>
                </div>

                <v-btn
                    class="tw-mx-auto tw-mt-auto tw-h-12 tw-w-40"
                    color="primary"
                    elevation="0"
                    rounded="pill"
                    target="_blank"
                    :to="{
                        name: 'Solution',
                        params: { slug: i.slug },
                    }">
                    <span class="tw-mr-1 tw-text-xs tw-text-white xl:tw-text-sm">Saiba mais</span>
                </v-btn>
            </article>
        </SplideSlide>
    </Splide>
</template>

<script setup lang="ts">
    import { Splide, SplideSlide } from '@splidejs/vue-splide';
    import { useMedia } from '../composables/useMedia';

    const props = defineProps<{
        content: any[];
    }>();

    const splideOptions = ref({
        type: 'slide',
        perPage: 4,
        perMove: 1,
        gap: '2rem',
        pagination: true,
        arrows: false,
        autoplay: true,
        interval: 5000,
        breakpoints: {
            1536: {
                perPage: 3
            },
            1279: {
                perPage: 2,
                gap: '1rem',
            },
            640: {
                perPage: 1,
                gap: '1rem',
            },
        },
    });
</script>

<style scoped></style>
