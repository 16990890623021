<template>
    <template v-if="page">
        <div class="light-grad tw-relative tw-flex tw-flex-col">
            <div class="io-container tw-relative tw-z-20 tw-my-0 tw-mb-24 tw-flex tw-flex-grow tw-flex-col tw-items-start tw-justify-start tw-py-8 tw-pb-0 tw-text-left 3xl:tw-pt-16">
                <h1
                    class="tw-mt-4 xl:tw-mt-10"
                    v-html="page.title"></h1>
                <div
                    class="h3 ckeditor tw-mt-3 md:tw-mt-6 lg:tw-mt-8"
                    v-html="page.description"></div>

                <div class="tw-mt-16 tw-grid tw-w-full tw-grid-cols-1 tw-gap-8 xl:tw-gap-12 lg:tw-grid-cols-3">
                    <!-- card -->
                    <div class="tw-flex tw-w-full tw-flex-col tw-gap-4 tw-rounded-4xl tw-bg-white tw-p-8 tw-shadow-lg sm:max-lg:tw-flex-row sm:max-lg:tw-items-center">
                        <!-- icon -->
                        <div class="tw-w-min tw-rounded-xl tw-bg-primary tw-px-4 tw-py-3">
                            <Marker class="tw-h-8 tw-w-6 tw-text-white" />
                        </div>
                        <!-- content -->
                        <div class="tw-flex tw-flex-col tw-gap-1 lg:tw-gap-3 tw-text-start sm:max-lg:tw-gap-0">
                            <h3 class="tw-justify-self-end tw-text-lg tw-font-semibold xl:tw-text-xl">Morada</h3>
                            <p class="tw-justify-self-start tw-text-dark xl:tw-text-lg">{{ page.address }}</p>
                        </div>
                    </div>
                    <!-- card -->
                    <div class="tw-flex tw-w-full tw-flex-col tw-gap-4 tw-rounded-4xl tw-bg-white tw-p-8 tw-shadow-lg sm:max-lg:tw-flex-row sm:max-lg:tw-items-center">
                        <!-- icon -->
                        <div class="tw-w-min tw-rounded-xl tw-bg-primary tw-px-4 tw-py-3">
                            <Email class="tw-h-8 tw-w-6 tw-text-white" />
                        </div>
                        <!-- content -->
                        <div class="tw-flex tw-flex-col tw-gap-1 lg:tw-gap-3 tw-text-start sm:max-lg:tw-gap-0">
                            <h3 class="tw-justify-self-end tw-text-lg tw-font-semibold xl:tw-text-xl">Email</h3>
                            <p class="tw-justify-self-start tw-text-dark xl:tw-text-lg">
                                <a :href="`mailto:${page.email}`">{{ page.email }}</a>
                            </p>
                        </div>
                    </div>
                    <!-- card -->
                    <div class="tw-flex tw-w-full tw-flex-col tw-gap-4 tw-rounded-4xl tw-bg-white tw-p-8 tw-shadow-lg sm:max-lg:tw-flex-row sm:max-lg:tw-items-center">
                        <!-- icon -->
                        <div class="tw-w-min tw-rounded-xl tw-bg-primary tw-px-4 tw-py-3">
                            <Phone class="tw-h-8 tw-w-6 tw-text-white" />
                        </div>
                        <!-- content -->
                        <div class="tw-flex tw-flex-col tw-gap-1 lg:tw-gap-3 tw-text-start sm:max-lg:tw-gap-0">
                            <h3 class="tw-justify-self-end tw-text-lg tw-font-semibold xl:tw-text-xl">Telefone</h3>
                            <p class="tw-justify-self-start tw-text-dark xl:tw-text-lg">
                                <a :href="`tel:${page.phoneNumber}`">{{ page.phoneNumberFormatted }}</a>
                            </p>
                            <p class="tw-mt-2 tw-text-xs tw-leading-none tw-text-gray-900">Chamada para a rede fixa nacional, de acordo com o seu tarifário</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <iframe
            allowfullscreen
            class="tw-w-full"
            height="450"
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
            :src="iframeUrl"
            style="border: 0">
        </iframe>
        <div
            id="contact-us"
            class="">
            <div class="io-container tw-relative tw-z-20 tw-my-0 tw-pb-24 tw-flex tw-flex-grow tw-flex-col tw-items-start tw-justify-start tw-pt-8 tw-text-center 3xl:tw-pt-16">
                <h1
                    class="tw-mt-10 tw-font-bold"
                    v-html="page.contactsUsTitle"></h1>
                <v-form
                    ref="form"
                    class="tw-mt-14 tw-w-full"
                    @submit.prevent="onFormSubmit">
                    <div class="tw-flex max-md:tw-flex-col tw-gap-8 lg:tw-gap-16">
                        <div class="tw-grow tw-text-left">
                            <v-label class="tw-mb-3 tw-text-lg tw-text-dark tw-opacity-100">Nome</v-label>
                            <v-text-field
                                v-model.trim="name"
                                flat
                                placeholder="O teu nome"
                                :rules="[$rules.required]"
                                variant="solo"></v-text-field>
                        </div>
                        <div class="tw-grow tw-text-left">
                            <v-label class="tw-mb-3 tw-text-lg tw-text-dark tw-opacity-100">Email</v-label>
                            <v-text-field
                                v-model.trim="email"
                                flat
                                placeholder="O teu email"
                                :rules="[$rules.required, $rules.email]"
                                type="email"
                                variant="solo"></v-text-field>
                        </div>
                    </div>
                    <div class="tw-mt-8 tw-w-full tw-text-left">
                        <v-label class="tw-mb-3 tw-text-lg tw-text-dark tw-opacity-100">Mensagem</v-label>
                        <v-textarea
                            v-model.trim="message"
                            class="message-area"
                            flat
                            placeholder="Mensagem"
                            :rules="[$rules.required]"
                            variant="solo"></v-textarea>
                    </div>
                    <div class="tw-text-right">
                        <p
                            v-if="response"
                            class="tw-mb-3 tw-text-lg"
                            :class="responseType === 'success' ? 'tw-text-green' : 'tw-text-red'">
                            {{ response }}
                        </p>
                        <v-btn
                            v-if="!response || (response && responseType === 'error')"
                            class="tw-px-8"
                            color="primary"
                            elevation="0"
                            height="50"
                            :loading="isLoading"
                            rounded="pill"
                            type="submit">
                            Enviar
                        </v-btn>
                    </div>
                </v-form>
            </div>
        </div>
    </template>
    <template v-else>
        <!-- loader -->
        <Loader />
    </template>
</template>

<script setup lang="ts">
    import Marker from '@/components/icons/marker.vue';
    import Email from '@/components/icons/email.vue';
    import Phone from '@/components/icons/phone.vue';
    import Loader from '@/components/Loader.vue';
    import { useMedia } from '@/composables/useMedia';
    import { useAppStore } from '@/store/app';
    import useRules from '@/composables/rules';
    import { VForm } from 'vuetify/components';
    import { createContactAttempt } from '@/api/contacts';

    const $rules = useRules();

    const appStore = useAppStore();

    const form = ref<VForm>();
    const email = ref('');
    const name = ref('');
    const message = ref('');
    const isLoading = ref(false);
    const response = ref('');
    const responseType = ref<'success' | 'error'>('error');

    const page = computed(() => {
        return appStore.getContacts;
    });

    const iframeUrl = computed(() => {
        return `https://www.google.com/maps/embed/v1/place?key=${import.meta.env.VITE_MAPS_KEY}&q=place_id:${page.value.gmapsPlaceId}`;
    });

    async function onFormSubmit() {
        if (!form.value) return;

        form.value.resetValidation();
        response.value = '';

        const { valid } = await form.value.validate();
        if (!valid) return;

        isLoading.value = true;

        try {
            await createContactAttempt({
                name: name.value,
                email: email.value,
                message: message.value,
            });

            response.value = 'Mensagem enviada com sucesso, entraremos em contacto em breve.';
            responseType.value = 'success';
            nextTick(() => {
                if (form.value) {
                    form.value.reset();
                }
            });
        } catch (error) {
            console.error(error);
            response.value = 'Ocorreu um erro ao enviar a mensagem. Por favor, tente novamente mais tarde.';
            responseType.value = 'error';
        } finally {
            isLoading.value = false;
        }
    }
</script>

<style scoped lang="scss">
    .light-grad {
        background: transparent linear-gradient(180deg, rgba(var(--v-theme-primary), 0.25) 0%, #80808000 100%) 0% 0% no-repeat padding-box;
    }

    #contact-us {
        @apply tw-relative tw-bg-blue-650 tw-bg-opacity-15;

        &::before {
            position: absolute;
            inset: 0;
            content: '';
            z-index: 1;
            background: url('@/assets/gradients/gradient-blue-650.png');
            background-position: center center;
            background-size: cover;
            filter: opacity(0.77);
            opacity: 0.16;
        }
    }
</style>

<style lang="scss">
    .v-form {
        .v-field {
            @apply tw-bg-white tw-bg-opacity-50 #{!important};
        }
    }

    .message-area .v-field {
        @apply tw-rounded-3xl #{!important};
    }
</style>
