<template>
    <template v-if="page">
        <section
            id="banner"
            class="tw-relative tw-flex tw-min-h-dvh tw-items-center tw-overflow-hidden tw-bg-cover tw-bg-center tw-bg-no-repeat"
            :style="{
                backgroundImage: `url(${bannerBgImage})`,
            }">
            <div class="io-container tw-text-white">
                <div class="sm:tw-max-w-2xl lg:tw-max-w-4xl">
                    <h6
                        v-if="page.bannerTag"
                        class="tw-w-max tw-rounded-full tw-border tw-border-white tw-px-6 tw-py-3 tw-text-sm lg:tw-text-base">
                        {{ page.bannerTag }}
                    </h6>
                    <div
                        v-if="page.bannerTitle"
                        class="tw-mt-10 tw-text-4xl tw-font-bold md:tw-text-5xl 3xl:tw-text-6xl"
                        v-html="page.bannerTitle"></div>
                    <div
                        v-if="page.bannerButtons?.length"
                        class="tw-mt-20 tw-flex tw-flex-col tw-items-center tw-gap-8 sm:tw-flex-row">
                        <div
                            v-for="btn in page.bannerButtons"
                            :key="btn.id">
                            <v-btn
                                class="tw-h-12 md:tw-h-14 md:tw-px-7 xl:tw-text-base"
                                :color="btn.type == 'flat' ? 'primary' : 'white'"
                                elevation="0"
                                rounded="pill"
                                :variant="btn.type">
                                <a :href="btn.url">{{ btn.text }}</a>
                            </v-btn>
                        </div>
                    </div>
                    <div
                        v-if="page.recognitions?.length"
                        class="tw-mt-24 tw-flex tw-gap-6">
                        <a
                            v-for="recognition in page.recognitions"
                            :key="recognition.id"
                            :href="recognition.url"
                            target="_blank">
                            <img
                                class="tw-w-24"
                                :src="useMedia(recognition.image).original" />
                        </a>
                    </div>
                </div>
            </div>
        </section>
        <section class="io-container radial-grad">
            <h2
                v-if="page.contentTitle"
                v-html="page.contentTitle"></h2>
            <HomepageContentSlider
                v-if="page.contentCards?.length"
                :content="page.contentCards" />
        </section>
        <section class="io-container radial-grad rg-right">
            <Tag
                v-if="page.solutionsTag"
                class="tw-mb-8"
                color="primary"
                :text="page.solutionsTag"></Tag>
            <h2
                v-if="page.solutionsTitle"
                v-html="page.solutionsTitle"></h2>
            <HomepageSolutionsSlider
                v-if="page.solutions?.length"
                :content="page.solutions" />
        </section>
        <section class="io-container tw-text-center">
            <Tag
                v-if="page.productsTag"
                class="tw-mx-auto tw-mb-8"
                color="primary"
                :text="page.productsTag"></Tag>
            <h2
                v-if="page.productsTitle"
                v-html="page.productsTitle"></h2>
            <ProductsGrid :products="page.products" />
        </section>
        <section class="io-container tw-text-center">
            <Tag
                v-if="page.clientsTag"
                class="tw-mx-auto tw-mb-8"
                color="primary"
                :text="page.clientsTag"></Tag>
            <h2
                v-if="page.clientsTitle"
                v-html="page.clientsTitle"></h2>
            <PartnersSlider :partners="clients" />
        </section>
        <section
            id="testemonies"
            class="tw-py-10">
            <div class="io-container tw-relative tw-z-[2]">
                <Tag
                    v-if="page.testimonyTag"
                    class="tw-mb-8"
                    color="primary"
                    :text="page.testimonyTag"></Tag>
                <h2
                    v-if="page.testimonyTitle"
                    v-html="page.testimonyTitle"></h2>
                <TestemoniesSlider
                    v-if="testimonies"
                    :content="testimonies" />
            </div>
        </section>
        <section class="io-container tw-text-center radial-grad rg-right">
            <Tag
                v-if="page.newsTag"
                class="tw-mx-auto tw-mb-8"
                color="primary"
                :text="page.newsTag"></Tag>
            <h2
                v-if="page.newsTitle"
                v-html="page.newsTitle"></h2>
            <div class="tw-mt-10 tw-grid tw-grid-cols-1 tw-gap-x-8 tw-gap-y-16 tw-text-left sm:tw-grid-cols-2 lg:tw-grid-cols-3 3xl:tw-grid-cols-4">
                <article
                    v-for="i in news"
                    :key="i.id"
                    class="tw-flex tw-h-full tw-flex-col">
                    <img
                        alt=""
                        class="tw-aspect-video tw-rounded-3xl tw-object-cover tw-object-center"
                        :src="i.image ? useMedia(i.image).small : i.imgUrl" />
                    <p class="tw-mt-6 tw-font-normal tw-text-dark">{{ parseDate(i.publishDate) }}</p>
                    <h4 class="tw-mt-3 tw-text-lg tw-font-semibold lg:tw-text-lg xl:tw-text-xl" v-html="i.title"></h4>
                    <div class="xl:tw-mt-3 xl:tw-text-lg" v-html="i.description"></div>
                    <div class="tw-mt-auto">
                        <v-btn
                            class="tw-mt-3 tw-h-10 tw-px-4 max-md:tw-ms-auto md:tw-me-auto xl:tw-mt-6"
                            color="blue"
                            rounded="pill">
                            <a
                                :href="i.url"
                                target="_blank"
                                >Ler mais</a
                            >
                        </v-btn>
                    </div>
                </article>
            </div>
        </section>
    </template>
    <template v-else>
        <!-- loader -->
        <Loader />
    </template>
</template>

<script lang="ts" setup>
    import { useAppStore } from '@/store/app';
    import { useMedia } from '../composables/useMedia';
    import { useDisplay } from 'vuetify';
    import Tag from '@/components/Tag.vue';
    import moment from 'moment';

    const appStore = useAppStore();
    const $display = useDisplay();

    const page = computed(() => {
        return appStore.getHomepage;
    });

    const bannerBgImage = computed(() => {
        return $display.lgAndUp.value ? useMedia(page.value?.bannerDesktopImage).original : useMedia(page.value?.bannerMobileImage).original;
    });

    const clients = computed(() => {
        return appStore.getClients;
    });

    const testimonies = computed(() => {
        return appStore.getTestimonies;
    });

    const news = computed(() => {
        return appStore.getNews.slice(0, 4);
    });

    function parseDate(date: string) {
        return moment(date).format('DD MMMM, YYYY');
    }

    onMounted(() => {
        document.querySelector('main.v-main')?.classList.add('lg:tw-p-0');
    });

    onUnmounted(() => {
        document.querySelector('main.v-main')?.classList.remove('lg:tw-p-0');
    });
</script>

<style scoped lang="scss">
    #testemonies {
        @apply tw-relative tw-bg-blue-650 tw-bg-opacity-15;

        &::before {
            position: absolute;
            inset: 0;
            content: '';
            z-index: 1;
            background: url('@/assets/gradients/gradient-blue-650.png');
            background-position: center center;
            background-size: cover;
            filter: opacity(0.77);
            opacity: 0.16;
        }
    }
</style>
