<template>
    <Splide :options="splideOptions">
        <SplideSlide
            v-for="(member, i) in props.team"
            :key="member.documentId">
            <div class="team-card tw-relative tw-aspect-square tw-overflow-hidden tw-rounded-4xl tw-bg-cover tw-bg-center tw-bg-no-repeat">
                <img
                    alt=""
                    class="tw-z-10 tw-h-full tw-w-full tw-object-cover tw-relative"
                    :src="useMedia(member.image).original" />
                <div class="tw-absolute tw-bottom-3 tw-left-3 tw-right-3 tw-z-10 tw-rounded-xl tw-bg-white tw-bg-opacity-90 tw-px-5 tw-py-4">
                    <h4 class="tw-text-sm tw-font-semibold tw-text-dark">{{ member.name }}</h4>
                    <p class="tw-text-xs tw-font-light tw-text-dark">{{ member.job }}</p>
                </div>
            </div>
        </SplideSlide>
    </Splide>
</template>

<script setup lang="ts">
    import { useMedia } from '@/composables/useMedia';
    import { Splide, SplideSlide } from '@splidejs/vue-splide';

    const props = defineProps<{
        team: {
            id: number;
            documentId: string;
            name: string;
            job: string;
            image: any;
            order: number;
        }[];
    }>();

    const splideOptions = ref({
        type: 'loop',
        perPage: 4,
        perMove: 1,
        gap: '4rem',
        pagination: false,
        arrows: false,
        autoplay: true,
        interval: 3000,
        breakpoints: {
            1535: {
                perPage: 3,
                gap: '2rem',
            },
            1000: {
                perPage: 2,
                gap: '1rem',
            },
            640: {
                perPage: 1,
                gap: '1rem',
            },
        },
    });
</script>

<style scoped>
    .team-card::before {
        content: '';
        background-image: url('@/assets/gradients/gradient-team-bg.png');
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 1;
        background-size: cover;
        background-repeat: no-repeat;
    }
</style>
