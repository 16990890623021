<template>
    <div
        class="gradient tw-text-left"
        :class="`gradient-${props.product.color}`">
        <div class="tw-flex tw-aspect-square tw-w-20 tw-items-center tw-justify-center tw-rounded-2xl tw-p-2 2xl:tw-w-24 3xl:tw-w-28">
            <img
                v-if="props.product.logo"
                alt=""
                class="tw-relative tw-object-contain"
                :src="useMedia(props.product.logo).thumbnail" />
        </div>
        <h5 class="tw-font-medium max-md:tw-text-base md:tw-text-xl">{{ props.product.name }}</h5>
        <p class="tw-mt-1 tw-font-light max-md:tw-text-sm 3xl:tw-text-lg">{{ props.product.description }}</p>
        <v-btn
            class="tw-ml-auto tw-h-10 tw-w-36 max-2xl:tw-mt-3 3xl:tw-h-12 3xl:tw-w-40"
            elevation="0"
            rounded="pill"
            :to="{
                name: 'Product',
                params: { slug: props.product.slug },
            }">
            <span class="tw-mr-1 tw-text-xs tw-text-white 3xl:tw-text-sm">Saiba mais</span>
            <v-icon class="tw-text-lg 3xl:tw-text-xl">mdi-arrow-top-right</v-icon>
        </v-btn>
    </div>
</template>

<script setup lang="ts">
    import { useMedia } from '@/composables/useMedia';

    const props = defineProps<{
        product: {
            name: string;
            description: string;
            color: string;
        };
    }>();
</script>

<style lang="scss">
    .gradient {
        @apply tw-relative tw-grid tw-items-center tw-rounded-4xl tw-p-6 tw-text-white md:tw-p-8;

        grid-template-areas:
            'icon name'
            'icon desc'
            'button button';
        grid-template-columns: 80px auto;
        // add a gap
        row-gap: 0;
        column-gap: 1rem;

        @media screen and (min-width: 1536px) {
            grid-template-areas:
                'icon name button'
                'icon desc button';
            grid-template-columns: 96px auto 1fr;
        }

        @media screen and (min-width: 1792px) {
            grid-template-columns: 112px auto 1fr;
        }

        & > div {
            @apply tw-relative;
            grid-area: icon;
            &::after {
                @apply tw-absolute tw-inset-0 tw-rounded-2xl tw-bg-white tw-opacity-80;
                content: '';
            }
        }

        & > h5 {
            @apply tw-self-end;
            grid-area: name;
        }

        & > p {
            @apply tw-self-start;
            grid-area: desc;
        }

        & > a {
            grid-area: button;
        }

        * {
            @apply tw-z-10;
        }

        &::before {
            @apply tw-absolute tw-inset-0 tw-rounded-4xl;
            content: '';
            z-index: 1;
        }
    }

    .gradient-blue-110,
    .gradient-iocity {
        @apply tw-bg-blue-110;

        a {
            @apply tw-text-white;
            background: linear-gradient(180deg, #0c82a7 0%, #188dac 100%);
        }

        &::before {
            background: url('@/assets/gradients/gradient-blue-110.png');
            background-position: center center;
            background-size: cover;
            filter: opacity(0.77);
            opacity: 0.48;
        }
    }

    .gradient-blue-650,
    .gradient-iotech {
        @apply tw-bg-blue-650;

        a {
            @apply tw-text-white;
            background: linear-gradient(180deg, #1b59a1 0%, #04479e 100%);
        }

        &::before {
            background: url('@/assets/gradients/gradient-blue-650.png');
            background-position: center center;
            background-size: cover;
            filter: opacity(0.77);
            opacity: 0.48;
        }
    }

    .gradient-red-600,
    .gradient-iotool {
        @apply tw-bg-red-600;

        a {
            @apply tw-text-white;
            background: linear-gradient(180deg, #a92834 0%, #a71827 100%);
        }

        &::before {
            background: url('@/assets/gradients/gradient-red-600.png');
            background-position: center center;
            background-size: cover;
            opacity: 0.36;
        }
    }

    .gradient-purple {
        @apply tw-bg-purple;

        a {
            @apply tw-text-white;
            background: linear-gradient(180deg, #9c78d1 0%, #9b76cc 100%);
        }

        &::before {
            background: url('@/assets/gradients/gradient-purple.png');
            background-position: center 60%;
            background-size: cover;
            opacity: 0.48;
        }
    }

    .gradient-green-100 {
        @apply tw-bg-green-100;

        a {
            @apply tw-text-white;
            background: linear-gradient(180deg, #98a965 0%, #90a65c 100%);
        }

        &::before {
            background: url('@/assets/gradients/gradient-green-100.png');
            background-position: center 75%;
            background-size: cover;
            opacity: 0.44;
        }
    }

    .gradient-orange,
    .gradient-ioscience {
        @apply tw-bg-orange;

        a {
            @apply tw-text-white;
            background: linear-gradient(180deg, rgb(var(--v-theme-orange)) 0%, rgb(var(--v-theme-orange)) 100%);
        }

        &::before {
            background: url('@/assets/gradients/gradient-orange.png');
            background-position: center 30%;
            background-size: cover;
            opacity: 0.54;
        }
    }
</style>
