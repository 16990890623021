<template>
    <Splide
        class="industry-splide"
        :options="splideOptions">
        <SplideSlide
            v-for="page in content"
            :key="page.id">
            <div
                class="connect-square tw-flex tw-h-full tw-w-full tw-flex-col tw-justify-between tw-rounded-4xl tw-px-6 tw-py-6 max-md:tw-aspect-square xl:tw-p-16"
                :style="{ backgroundImage: page.image ? `url(${useMedia(page.image).original})` : '' }">
                <div class="tw-flex tw-w-full tw-items-center tw-justify-between">
                    <v-btn
                        v-if="page.knowMoreUrl"
                        class="tw-h-12 tw-w-40 xl:tw-h-14 xl:tw-w-48 3xl:tw-h-16 3xl:tw-w-52"
                        color="blue"
                        elevation="0"
                        rounded="pill"
                        :to="page.knowMoreUrl">
                        <span class="tw-mr-1 tw-text-xs xl:tw-mr-3 xl:tw-text-base 3xl:tw-text-lg">Saiba mais</span>
                        <v-icon class="md:tw-text-xl xl:tw-text-2xl">mdi-arrow-top-right</v-icon>
                    </v-btn>
                    <img
                        v-if="page.icon"
                        class="tw-ml-auto tw-aspect-square tw-w-12 xl:tw-w-16"
                        :src="useMedia(page.icon).thumbnail" />
                </div>
                <div class="tw-mt-16 tw-text-white">
                    <h4
                        class="tw-text-lg tw-font-medium max-md:tw-text-base xl:tw-text-2xl 3xl:tw-text-3xl"
                        v-html="page.title"></h4>
                    <p
                        class="tw-mt-3 tw-font-light max-md:tw-text-sm xl:tw-text-lg 3xl:tw-text-xl"
                        v-html="page.description"></p>
                </div>
            </div>
        </SplideSlide>
    </Splide>
</template>

<script setup lang="ts">
    import { Splide, SplideSlide } from '@splidejs/vue-splide';
    import { useMedia } from '../composables/useMedia';

    const props = defineProps<{
        content: any[];
    }>();

    const splideOptions = ref({
        type: 'slide',
        perPage: 1,
        perMove: 1,
        gap: '4rem',
        pagination: true,
        arrows: false,
        autoplay: true,
        interval: 5000,
        rewind: true,
    });
</script>

<style scoped>
    .connect-square {
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
    }
</style>
<style>
    .industry-splide,
    .industry-splide .splide__track {
        height: 100%;
    }
</style>
