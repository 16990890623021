<template>
    <Splide :options="splideOptions">
        <SplideSlide
            v-for="i in content"
            :key="i.id">
            <article class="tw-relative tw-aspect-square tw-mx-auto max-md:tw-max-w-xs sm:xl:tw-aspect-[2/1] md:tw-aspect-[3/2] tw-overflow-hidden tw-rounded-3xl tw-px-6 tw-py-3 3xl:tw-px-14 3xl:tw-py-10">
                <img
                    alt=""
                    class="tw-absolute tw-inset-0 tw-z-[1] tw-aspect-square sm:xl:tw-aspect-[2/1] md:tw-aspect-[3/2] tw-w-full tw-object-cover tw-object-center"
                    :src="useMedia(i.image).small" />
                <div class="tw-relative tw-z-[2] tw-flex tw-flex-col tw-h-full">
                    <h4
                        v-if="i.title"
                        class="tw-mt-3 tw-text-sm 3xl:tw-text-base"
                        v-html="i.title"></h4>
                    <div
                        v-if="i.text"
                        class="tw-mt-2 tw-text-lg 3xl:tw-text-2xl tw-font-bold"
                        v-html="i.text"></div>
                    <v-btn
                        v-if="i.knowMoreUrl"
                        class="tw-mt-auto tw-h-12 tw-w-40 tw-bg-blue-900 tw-bg-opacity-90"
                        elevation="0"
                        rounded="pill"
                        target="_blank"
                        :to="i.knowMoreUrl">
                        <span class="tw-mr-1 tw-text-xs tw-text-white xl:tw-text-sm">Saiba mais</span>
                        <v-icon class="xl:tw-text-xl tw-text-white">mdi-arrow-top-right</v-icon>
                    </v-btn>
                </div>
            </article>
        </SplideSlide>
    </Splide>
</template>

<script setup lang="ts">
    import { Splide, SplideSlide } from '@splidejs/vue-splide';
    import { useMedia } from '../composables/useMedia';

    const props = defineProps<{
        content: any[];
    }>();

    const splideOptions = ref({
        type: 'slide',
        perPage: 2,
        perMove: 1,
        gap: '4rem',
        pagination: true,
        arrows: false,
        autoplay: true,
        interval: 5000,
        breakpoints: {
            1200: {
                perPage: 2,
                gap: '1rem',
            },
            768: {
                perPage: 1,
                gap: '1rem',
            },
        },
    });
</script>

<style scoped></style>
