<template>
    <Splide :options="splideOptions">
        <SplideSlide
            v-for="i in responsabilities"
            :key="i.id">
            <article class="tw-flex tw-flex-col">
                <div class="tw-relative tw-aspect-[2/1] tw-overflow-hidden tw-rounded-3xl">
                    <img
                        alt=""
                        class="tw-aspect-[2/1] tw-w-full tw-object-cover tw-object-center"
                        :src="useMedia(i.image).small" />
                    <p
                        class="tw-absolute tw-bottom-5 tw-left-5 tw-rounded-full tw-px-5 tw-py-2 tw-font-medium tw-text-white tw-text-xs lg:tw-text-sm"
                        :class="`tw-bg-${i.color}`">
                        {{ i.name }}
                    </p>
                </div>
                <h4 class="tw-mt-3 tw-text-lg tw-font-semibold">{{ i.title }}</h4>
                <div class="tw-mt-2">
                    {{ i.description }}
                </div>
            </article>
        </SplideSlide>
    </Splide>
</template>

<script setup lang="ts">
    import { Splide, SplideSlide } from '@splidejs/vue-splide';
    import { useMedia } from '../composables/useMedia';

    const props = defineProps<{
        responsabilities: any[];
    }>();

    const splideOptions = ref({
        type: 'slide',
        perPage: 3,
        perMove: 1,
        gap: '4rem',
        pagination: true,
        arrows: false,
        autoplay: true,
        interval: 5000,
        breakpoints: {
            1200: {
                perPage: 2,
                gap: '3rem',
            },
            640: {
                perPage: 1,
                gap: '1rem',
            },
        },
    });
</script>

<style scoped></style>
