import { colord } from 'colord';

const colors = createVariations({
    iotech: '#2D5FA9',
    iotool: '#D1284C',
    ioapp: '#00ACC9',
    iocity: '#01acca',
    ioscience: '#F9A61F',
    ioserv: '#ffc107',
    iohub: '#00A144',
    ioacademy: '#6A1586',
    black: '#000000',
    dark: '#333333',
    primary: {
        DEFAULT: '#2D5FA9',
    },
    green: {
        10: '#E3F7EC',
        100: '#CDD29C',
        300: '#5E9B4E',
        DEFAULT: '#00A144',
    },
    blue: {
        10: '#F0FBFF',
        20: '#EFFCFF',
        30: '#EBF2FB',
        35: '#c4d3eb',
        40: '#b6cde8',
        50: '#B9CBE7',
        60: '#A1B6D4',
        70: '#6d9df4',
        80: '#61ABC7',
        100: '#00A8C9',
        110: '#0b84a8',
        200: '#34A8DB',
        250: '#35A8DF',
        400: '#4875b4',
        DEFAULT: '#2D5FA9',
        600: '#1C6FB8',
        625: '#1D6FB8',
        650: '#1D70B7',
        700: '#175DC6',
        900: '#212F43',
    },
    red: {
        10: '#FDECEF',
        20: '#F3DFE3',
        400: '#CF4560',
        DEFAULT: '#B11212',
        600: '#B52B4D',
        700: '#8A2E2E',
        900: '#4B1616',
    },
    orange: {
        10: '#FBF4E6',
        50: '#FAE4BF',
        DEFAULT: '#F9B233',
        600: '#E5A437',
        650: '#E5A539',
    },
    purple: {
        400: '#B18CFF',
        DEFAULT: '#AF89FF',
        900: '#6A1586',
    },
    gray: {
        40: '#F6F6F6',
        50: '#F4F4F4',
        100: '#F1F1F1',
        200: '#E4E4E4',
        300: '#E1E1E1',
        400: '#DADADA',
        DEFAULT: '#BDBDBD',
        700: '#959595',
        800: '#8F8F8F',
        900: '#707070',
    },
    white: '#FFFFFF',
});

export const breakpointsTailwind = {
    '3xs': '320px',
    '2xs': '375px',
    xs: '425px',
    sm: '640px',
    md: '768px',
    lg: '1024px',
    xl: '1280px',
    '2xl': '1536px',
    '3xl': '1792px',
};

export const theme = {
    colors,
    fontFamily: {
        sans: ['Inter', 'sans-serif'],
        montserrat: ['Montserrat', 'sans-serif'],
    },
} as any;

export default theme;

function createVariations(colors: any) {
    const variations: any = {};
    for (const color in colors) {
        if (['transparent', 'current', 'black', 'white'].includes(color)) {
            variations[color] = colors[color];
            continue;
        }
        if (typeof colors[color] === 'object') {
            for (const shade in colors[color]) {
                if (shade.toUpperCase() != 'DEFAULT') variations[`${color}-${shade}`] = colors[color][shade];
                else variations[color] = colors[color][shade];
            }
            continue;
        }

        variations[color] = colors[color];
        const col = colord(colors[color]);
        for (let i = 0.05; i <= 0.2; i += 0.05) {
            variations[`${color}-${Math.abs(Math.round(i * 2000 - 500))}`] = col.lighten(i).toHex();
        }
        for (let i = 0.05; i <= 0.2; i += 0.05) {
            variations[`${color}-${Math.abs(Math.round(i * 2000 + 500))}`] = col.darken(i).toHex();
        }
    }
    return variations;
}

export function vuetifyColors() {
    const vuetifyColors: any = {};
    for (const color in theme.colors) {
        if (['transparent', 'current'].includes(color)) continue;
        vuetifyColors[color] = theme.colors[color];
    }
    return vuetifyColors;
}

export function tailwindColors() {
    const tailwindColors: any = {};
    for (const color in theme.colors) {
        if (['transparent', 'current'].includes(color)) {
            tailwindColors[color] = theme.colors[color];
            continue;
        }
        tailwindColors[color] = `rgba(var(--v-theme-${color}), <alpha-value>)`;
    }

    return tailwindColors;
}
