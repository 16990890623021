<template>
    <div class="min-h-dvh tw-flex tw-items-center tw-justify-center">
        <v-progress-circular
            color="primary"
            indeterminate></v-progress-circular>
    </div>
</template>

<script setup lang="ts"></script>

<style scoped></style>
