<template>
    <template v-if="page">
        <div class="min-h-dvh tw-relative tw-flex tw-flex-col tw-overflow-hidden radial-grad">
            <div class="io-container tw-relative tw-z-20 tw-my-0 tw-grid tw-flex-grow tw-grid-cols-1 tw-gap-16 tw-py-8 xl:tw-grid-cols-12 3xl:tw-pt-16">
                <div class="tw-relative tw-z-30 tw-order-2 xl:tw-col-span-6">
                    <Tag
                        :color="page.color"
                        :text="page.name" />
                    <img
                        v-if="page.bannerImage"
                        alt=""
                        class="tw-relative tw-left-1/2 tw-my-8 tw-max-h-96 tw-max-w-[calc(100%+100px)] -tw-translate-x-1/2 xl:tw-hidden"
                        :src="useMedia(page.bannerImage).original" />
                    <h1
                        class="tw-mt-6"
                        v-html="page.bannerTitle"></h1>
                    <div
                        class="h3 ckeditor tw-mt-3 md:tw-mt-6 lg:tw-mt-8"
                        v-html="page.bannerDescription"></div>
                </div>
                <div class="tw-relative tw-order-1 max-xl:tw-hidden xl:tw-col-span-6">
                    <img
                        v-if="page.bannerImage"
                        alt=""
                        class="desktop-image tw-absolute tw-inset-0 tw-z-10 tw-mx-auto tw-h-full tw-object-contain max-md:tw-hidden"
                        :src="useMedia(page.bannerImage).original" />
                </div>
            </div>
            <div class="io-container tw-z-20">
                <h2
                    class=""
                    v-html="page.processTitle"></h2>
                <h3
                    class="ckeditor tw-mb-16"
                    v-html="page.processDescription"></h3>
                <!-- <ProcessCard class="tw-absolute" /> -->
                <div class="tw-flex tw-flex-col tw-justify-between tw-gap-8 xl:tw-flex-row">
                    <div
                        v-for="(step, i) in page.processes"
                        :key="step.id"
                        class="tw-relative tw-flex tw-flex-col tw-gap-4 tw-rounded-4xl xl:tw-h-[550px]"
                        :class="`${openProcess === i ? 'xl:tw-max-w-[650px]' : ''}`"
                        @click="openProcess = i">
                        <img
                            alt=""
                            class="tw-absolute tw-z-[-1] tw-h-full tw-max-h-none tw-w-full tw-max-w-none tw-rounded-4xl tw-object-cover tw-opacity-70"
                            src="@/assets/gradients/gradient-blue-110.png"
                            :style="{
                                objectPosition: `${i * 15}% 50%`,
                            }" />
                        <div
                            class="tw-relative tw-grow"
                            :class="`${openProcess !== i ? 'writing-sideways-lr tw-h-full tw-px-10 tw-py-6 xl:tw-py-12' : 'process-img-container tw-px-8 tw-pt-12 xl:tw-px-12'}`">
                            <h6
                                class="tw-font-bold"
                                :class="`${openProcess !== i ? 'tw-text-lg tw-text-white xl:tw-text-3xl' : 'tw-text-lg tw-text-blue xl:tw-text-2xl'}`">
                                {{ step.title }}
                            </h6>
                            <p
                                class="tw-mt-5 tw-pb-4 tw-font-normal tw-text-white xl:tw-text-lg"
                                :class="`${openProcess === i ? '' : 'tw-hidden'}`">
                                {{ step.description }}
                            </p>
                        </div>
                        <div
                            class="tw-relative tw-flex tw-items-end tw-justify-end tw-overflow-hidden"
                            :class="`${openProcess !== i ? 'tw-hidden' : ''}`">
                            <div
                                class="process-img tw-relative tw-aspect-[408/325] tw-h-full tw-max-h-[275px] tw-rounded-tl-[39px] tw-border-l-[20px] tw-border-t-[20px] tw-border-solid tw-border-white tw-bg-white max-md:tw-w-full">
                                <div class="tw-h-full tw-w-full tw-overflow-hidden tw-rounded-[39px]">
                                    <img
                                        alt=""
                                        class="tw-object-cover"
                                        :src="useMedia(step.image).medium" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="io-container radial-grad rg-right">
                <h2
                    v-if="page.teamTitle"
                    class="tw-text-center"
                    v-html="page.teamTitle"></h2>
                <h2
                    v-else
                    class="tw-text-center ckeditor">
                    Conhece a <span class="tw-text-blue">nossa equipa</span>
                </h2>

                <h3
                    v-if="page.teamDescription"
                    class="tw-text-center tw-mb-16 ckeditor"
                    v-html="page.teamDescription"></h3>
                <TeamSlider
                    v-if="team"
                    :team="team" />
            </div>
        </div>
    </template>
    <template v-else>
        <!-- loader -->
        <Loader />
    </template>
</template>

<script setup lang="ts">
    import ProcessCard from '@/components/ProcessCard.vue';
    import TeamSlider from '@/components/TeamSlider.vue';
    import Loader from '@/components/Loader.vue';
    import { useMedia } from '@/composables/useMedia';
    import { useAppStore } from '@/store/app';

    const $router = useRouter();
    const appStore = useAppStore();

    const openProcess = ref(0);

    const page = computed(() => {
        return appStore.getAboutUs;
    });

    const team = computed(() => {
        return appStore.getTeam;
    });

    const pageColor = computed(() => (page.value ? `rgb(var(--v-theme-${page.value.color}))` : ''));

    watchEffect(() => {
        if (!page.value && appStore.hasFinishedLoading) {
            $router.push({ name: 'NotFound' });
        }
    });

    watchEffect(() => {
        // set the css variable --page-color
        if (pageColor.value) {
            document.documentElement.style.setProperty('--page-color', pageColor.value);
        }
    });
</script>

<style scoped>
    .card {
        position: relative;
    }

    .card > * {
        position: relative;
        z-index: 1;
    }

    .card::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        /* background-color: rgba(var(--v-theme-blue),1) !important; */
        background-image: url('@/assets/gradients/gradient-blue-110.png');
        background-size: cover;
        background-repeat: no-repeat;
        /* opacity: 0.67; */
        border-radius: inherit;
    }

    .process-img::after,
    .process-img-container::after {
        --_size: 39px;
        --_corner-color: white;
        content: '';
        position: absolute;
        width: var(--_size);
        aspect-ratio: 1;
        background-image: radial-gradient(circle at 100% 100%, transparent var(--_size), var(--_corner-color, white) calc(var(--_size) + 1px));
    }

    .process-img::after {
        rotate: 180deg;
        bottom: 0;
        left: 0;
        transform: translateX(calc(100% + 19px));
    }

    .process-img-container::after {
        rotate: 180deg;
        bottom: 0;
        right: 0;
        transform: translateY(calc(-100% + 23px));
        z-index: 2;
    }

    @media (min-width: 1280px) {
        .writing-sideways-lr {
            writing-mode: sideways-lr;
        }
    }
</style>
