<template>
    <Splide :options="splideOptions">
        <SplideSlide
            v-for="partner in props.partners"
            :key="partner.documentId">
            <a
                :href="partner.website"
                target="_blank">
                <v-img
                    class="tw-mx-auto tw-aspect-square tw-w-32 md:tw-w-44"
                    :src="useMedia(partner.image).medium"></v-img>
            </a>
        </SplideSlide>
    </Splide>
</template>

<script setup lang="ts">
    import { useMedia } from '@/composables/useMedia';
    import { Splide, SplideSlide } from '@splidejs/vue-splide';

    const props = defineProps<{
        partners: any[];
        sideways?: boolean;
    }>();

    const splideOptions = computed(() => ({
        focus: props.partners.length > 6 || props.sideways ? undefined : 'center',
        type: 'slide',
        trimSpace: props.partners.length > 6,
        perPage: 6,
        start: props.partners.length > 6 || props.sideways ? 0 : props.partners.length / 2 - 1,
        perMove: 1,
        gap: '2rem',
        pagination: true,
        arrows: false,
        autoplay: true,
        interval: 3000,
        breakpoints: {
            1535: {
                perPage: 5,
                gap: '2rem',
            },
            1200: {
                perPage: 3,
                gap: '1rem',
            },
            640: {
                perPage: 2,
                perMove: 2,
                gap: '1rem',
            },
        },
    }));
</script>

<style scoped></style>
