<template>
    <template v-if="page">
        <div class="min-h-dvh radial-grad tw-relative tw-flex tw-flex-col tw-overflow-hidden">
            <div class="io-container tw-relative tw-z-20 tw-my-0 tw-flex tw-flex-grow tw-flex-col tw-items-center tw-justify-start tw-py-8 tw-pb-0 tw-text-center 3xl:tw-pt-16">
                <Tag
                    :color="page.color"
                    :text="page.name" />
                <h1
                    class="tw-mt-10"
                    v-html="page.bannerTitle"></h1>
                <div
                    class="h3 ckeditor tw-mt-3 md:tw-mt-6 lg:tw-mt-8"
                    v-html="page.bannerDescription"></div>
                <div
                    v-if="page.bannerImage || page.bannerImageMobile"
                    class="banner-img tw-relative tw-mt-16 tw-w-full">
                    <img
                        v-if="page.bannerImage"
                        alt=""
                        class="tw-relative tw-z-10 max-lg:tw-hidden"
                        :src="useMedia(page.bannerImage).original" />
                    <img
                        v-if="page.bannerImageMobile"
                        alt=""
                        class="tw-relative tw-z-10 lg:tw-hidden"
                        :src="useMedia(page.bannerImageMobile).original" />
                </div>
            </div>
        </div>
        <div class="excellence tw-relative">
            <div class="io-container tw-mt-0 tw-py-16">
                <h2
                    class=""
                    v-html="page.excellenceTitle"></h2>
                <div class="tw-grid tw-grid-cols-1 tw-gap-8 xl:tw-grid-cols-2 2xl:tw-grid-cols-3">
                    <div
                        v-for="excellence in page.excellenceBases"
                        :key="excellence.id"
                        class="tw-relative tw-flex tw-flex-col tw-gap-4">
                        <div class="tw-flex tw-items-center tw-justify-start tw-gap-5">
                            <div class="tw-flex tw-h-12 tw-w-12 tw-items-center tw-justify-center tw-rounded-xl tw-bg-blue-40 tw-p-3">
                                <img
                                    alt=""
                                    :src="useMedia(excellence.icon).original" />
                            </div>
                            <h3 class="tw-font-bold">
                                {{ excellence.title }}
                            </h3>
                        </div>
                        <h3
                            class="tw-font-light"
                            v-html="excellence.description"></h3>
                    </div>
                </div>
            </div>
        </div>
        <div
            v-if="timelineItems.length"
            class="io-container tw-text-center">
            <h2
                class=""
                v-html="page.historyTitle"></h2>
            <h3
                v-if="page.historyDescription"
                class="ckeditor tw-mb-16"
                v-html="page.historyDescription"></h3>

            <v-timeline
                v-if="timelineItems.length"
                ref="timeline"
                align="center"
                :class="{ 'my-timeline': shouldAnimate, 'tw-justify-center': timelineDirection === 'vertical' }"
                :direction="timelineDirection"
                dot-color="primary">
                <v-timeline-item
                    v-for="(history, index) in timelineItems"
                    :key="history.id"
                    >
                    <template #opposite>
                        <p
                            class="tw-w-full tw-text-sm"
                            :class="getTextAlign(index, true)">
                            {{ history.date }}
                        </p>
                    </template>
                    <p :class="getTextAlign(index)">
                        {{ history.event }}
                    </p>
                </v-timeline-item>
            </v-timeline>
        </div>
        <div
            v-if="page.responsabilities?.length > 0"
            class="io-container tw-pt-8">
            <h2
                class=""
                v-html="page.responsabilityTitle"></h2>
            <ResponsabilitiesSlider :responsabilities="page.responsabilities" />
        </div>
    </template>
    <template v-else>
        <!-- loader -->
        <Loader />
    </template>
</template>

<script setup lang="ts">
    import Loader from '@/components/Loader.vue';
    import { useMedia } from '@/composables/useMedia';
    import { useAppStore } from '@/store/app';
    import { useBreakpoints, useElementSize } from '@vueuse/core';
    import { breakpointsTailwind } from '../../../theme';
    import { VTimeline } from 'vuetify/components';
    import ResponsabilitiesSlider from '@/components/ResponsabilitiesSlider.vue';

    const $router = useRouter();
    const appStore = useAppStore();
    const breakpoints = useBreakpoints(breakpointsTailwind);

    const timeline = useTemplateRef<VTimeline>('timeline');

    const timelineItems = computed(() => {
        const t = appStore.getOurIdentity?.timeline || [];
        // return [...t, ...t];
        return t;
    });

    const page = computed(() => {
        return appStore.getOurIdentity;
    });

    const animationDuration = computed(() => {
        return timelineItems.value?.length * 3 + 's';
    });

    const shouldAnimate = computed(() => {
        const { width } = useElementSize(timeline);

        return breakpoints.greaterOrEqual('xl').value && timeline.value?.$el?.scrollWidth > width.value;
    });

    const timelineDirection = computed(() => {
        return 'vertical';
    });

    function getTextAlign(index: number, opposite = false) {
        if (timelineDirection.value === 'horizontal') return 'tw-text-center';

        return index % 2 === 0 ? (opposite ? 'tw-text-right' : 'tw-text-left') : opposite ? 'tw-text-left' : 'tw-text-right';
    }
</script>

<style scoped>
    .bg-gradient {
        position: relative;
        background-image: url('@/assets/gradients/gradient-blue-110.png');
        background-size: cover;
    }

    .banner-img > * {
        z-index: 2;
        position: relative;
    }

    .banner-img::before, .banner-img::after {
        z-index: 1;
        content: '';
        position: absolute;
        left: -25%;
        top: 0;
        right: -25%;
        bottom: 0;
        transform: translateY(50%);
        background-color: rgb(var(--v-theme-blue-30));
        background-size: cover;
    }

    .banner-img::after {
        transform: translateY(75%);
        bottom: -99%;
    }

    .excellence > * {
        z-index: 4;
        position: relative;
    }

    .excellence::before {
        z-index: 1;
        content: '';
        position: absolute;
        left: -25%;
        top: 0;
        right: -25%;
        bottom: 0;
        /* transform: translateY(-25%); */
        background-color: rgb(var(--v-theme-blue-30));
        background-size: cover;
    }
</style>

<style>
    @keyframes scroll {
        0% {
            transform: translateX(50%);
        }
        100% {
            transform: translateX(-100%);
        }
    }

    .my-timeline {
        animation-direction: alternate;
        animation-duration: v-bind(animationDuration);
        animation-fill-mode: none;
        animation-name: scroll;
        animation-timing-function: linear;
        animation-iteration-count: infinite;
    }
</style>
